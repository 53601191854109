<template>
  <form @submit="$event.preventDefault()">
    <div v-for="field in formData.fields" class="form__wrapper">
      <Checkbox
        v-if="formData.type === 'checkbox'"
        :name="formData.name"
        :default-value="field.value"
        :label="field.label"
        v-model="value"
      ></Checkbox>
      <Radio
        v-else-if="formData.type === 'radio'"
        :name="formData.name"
        :default-value="field.value"
        :label="field.label"
        v-model="value"
      ></Radio>
    </div>

    <div class="content__buttonBox">
      <button
        v-if="!isFirst"
        @click="$store.commit('prev')"
        class="button_def button__size button__type_2"
      >Назад
      </button>

      <div class="growOn"></div>

      <button
        :disabled="!isValid"
        @click="$store.commit('next')"
        class="button_def button__size button__type_1 text_md medium "
      >
        {{ isLast ? 'Показать результат' : 'Следующий вопрос' }}
      </button>
    </div>
  </form>
</template>

<script>
import Checkbox from './forms/Checkbox'
import Radio from './forms/Radio'
import DynamicFormComponent from '@/modules/quiz/components/DynamicFormComponent'

export default {
  name: 'dynamic-form',
  mixins: [DynamicFormComponent],
  components: {
    Checkbox,
    Radio
  }
}
</script>