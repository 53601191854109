<template>
  <form @submit="$event.preventDefault()">
    <div class="mb_10">
      <div class="row-flex d_f f_w_w" v-if="value">
        <div class="col-md-6 col-xs-12 mb_10 pos_rel">
          <input
            class="poll-border b-castom-field-item bl-theme-interactive"
            type="text"
            placeholder="от"
            :value="fromValue"
            @blur="fromValue = $event.target.value"
            :disabled="loaderPrice"
          />
          <div class="b-help-item pr_10"><span class="rub">млн руб.</span></div>
        </div>
        <div class="col-md-6 col-xs-12 mb_10 pos_rel">
          <input
            class="poll-border b-castom-field-item bl-theme-interactive"
            type="text"
            placeholder="до"
            :value="toValue"
            @blur="toValue = $event.target.value"
            :disabled="loaderPrice"
          />
          <div class="b-help-item pr_10"><span class="rub">млн руб. <!--₽--></span></div>
        </div>
        <div class="col-md-12 col-xs-12 mb_10">
          <div class="poll-slider-container br_2 d_f a_i_c j_c_c pos_rel ">
            <div class="poll-slider-line">
              <vue-slider
                v-model="value"
                :min="minRange"
                :max="maxRange"
                :minRange="1"
                :enable-cross="false"
                :tooltip="'none'"
              ></vue-slider>
              <!--<div style="transform: translateX(30px);" class="poll-slider-dot pos_a br_all z_i_2 c_p"></div>
              <div style="transform: translateX(100px);"  class="poll-slider-dot pos_a br_all z_i_2 c_p"></div>
              <div style="width:70px;left:30px" class="poll-slider-process pos_a z_i_1"></div>-->
            </div>
          </div>

        </div>
      </div>
    </div>

    <div v-if="error" class="alert alert-danger">{{ error }}</div>

    <div class="d_f">
      <button
        v-if="!isFirst"
        @click="$store.commit('prev')"
        class="p_15 bgr_f5f5f5 name_color d_i_f mr_10 br_2 btn-svg-item-2"
      >
        <div class="">
          <svg fill="#212121" class="w_24">
            <use xlink:href="#arrowback-24"/>
          </svg>
        </div>
        <div class="ml_15 fs_16 lh_24 fw_m hidden-xs pr_5">Назад</div>
      </button>

      <button
        :disabled="!isValid"
        @click="getResult"
        class="p_15 bgr_f5f5f5 name_color btn-svg-item br_2 pl_20 d_f "
      >
        <div class="fs_16 lh_24 fw_m ">{{ isLast ? 'Показать результат' : 'Следующий вопрос' }}</div>
        <div class=" ml_15 ">
          <svg fill="currentColor" class="w_24">
            <use xlink:href="#arrowforward-24"/>
          </svg>
        </div>
      </button>
    </div>
  </form>
</template>

<script>
import vueSlider from 'vue-slider-component'
import PriceFormComponent from '@/modules/quiz/components/PriceFormComponent'

export default {
  name: 'price-form',
  mixins: [PriceFormComponent],
  components: { vueSlider }
}
</script>