<template>
  <div class="listing__items listing__container">
    <div class="list-item-wrap" v-for="(item, index) in fakeListItems">
      <div class="list-item">
        <div class="list-item__header">
          <div class="list-item__title">{{ item.title }}</div>
          <div class="list-item__addr">{{ item.addr }}</div>
          <ul class="list-item__go">
            <li><span style="background: #FCA82A;" class="metro-icon"></span> {{ item.metro }}</li>
            <li>
              <svg width="16" height="16" viewBox="0 0 16 16" class="people" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.5">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M8.17804 5.16916L8.23738 5.18098C8.29169 5.18486 8.34553 5.20295 8.39254 5.23617L9.80436 6.23372C10.0329 6.3952 10.1677 6.65061 10.1677 6.9221V8.29963C10.1677 8.45727 10.0339 8.58507 9.86883 8.58507C9.70376 8.58507 9.56994 8.45727 9.56994 8.29963V6.9221C9.56994 6.8316 9.52499 6.74647 9.44881 6.69264L8.7308 6.18498L8.73132 8.29963C8.73095 8.45396 8.66588 8.60757 8.53766 8.72005L6.93332 10.1275C6.84536 10.2046 6.78363 10.3052 6.75607 10.4161L6.17962 12.7373C6.10343 13.0441 5.78127 13.2338 5.46003 13.1611C5.1388 13.0883 4.94015 12.7806 5.01633 12.4739L5.59278 10.1527C5.67546 9.81973 5.86066 9.51809 6.12452 9.28661L6.93985 8.57067L6.93999 6.08473C6.93999 5.89402 7.03972 5.7159 7.20579 5.60999L7.75636 5.25884C7.88323 5.17793 8.03394 5.14831 8.17804 5.16916ZM7.73078 10.1393L8.60025 9.41972C8.64335 9.44671 8.68325 9.47935 8.71865 9.5174L8.76821 9.57851L10.676 12.287C10.8603 12.5486 10.7876 12.9033 10.5136 13.0793C10.2593 13.2427 9.92084 13.1945 9.72625 12.9773L9.684 12.9242L7.77621 10.2158C7.75878 10.191 7.74366 10.1655 7.73078 10.1393L8.60025 9.41972L7.73078 10.1393ZM6.67321 6.99398V7.74982L5.97802 8.32762C5.86728 8.41955 5.70582 8.41981 5.59525 8.33517L5.55635 8.29947C5.46009 8.19372 5.45981 8.03951 5.54844 7.93391L5.58583 7.89678L6.67321 6.99398ZM6.85017 2.82367C7.51045 2.82367 8.04572 3.33485 8.04572 3.96542C8.04572 4.59599 7.51045 5.10717 6.85017 5.10717C6.18988 5.10717 5.65461 4.59599 5.65461 3.96542C5.65461 3.33485 6.18988 2.82367 6.85017 2.82367Z"
                        fill="#2B2D36"/>
                </g>
              </svg>
              {{ item.time }}
            </li>
          </ul>
        </div>
        <div class="list-item__footer">
          <div class="list-item__price">{{ item.price }}</div>
          <div class="list-item__counts">{{ item.counts }}</div>
        </div>
      </div><!--end list-item -->
    </div><!--end list-item-wrap -->

  </div>
</template>
<style lang="scss" scoped>
.listing__items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1040px;
  grid: none;
  grid-gap: unset;
  grid-template: none;
  overflow: auto;
  height: auto;
  padding: 0 0 10px;
}

.listing--blur {
  filter: blur(3px)
}

.listing--blur2 {
  filter: blur(2px)
}

.list-item {
  border: 1px solid #E6E6E6;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 32px 24px;
  color: #2B2D36;
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  
  &-wrap {
    width: 33.33%;
    padding: 10px;
    @media(max-width: 768px) {
      width: 50%;
    }
    @media(max-width: 650px) {
      width: 100%;
      padding: 10px 0;
    }
  }
  
  &__header {
    padding-bottom: 16px;
    flex: 1 1 auto;
  }
  
  &__title {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 4px;
  }
  
  &__addr {
    padding-bottom: 2px;
  }
  
  &__footer {
    border-top: 1px solid #E6E6E6;
    padding-top: 16px;
    font-size: 16px;
    
  }
  
  &__price {
    font-weight: 700;
  }
  
  &__counts {
  
  }
  
  .list-item__go {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    
    li {
      display: flex;
      margin-right: 3px;
      align-items: center;
    }
    
    .metro-icon {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: top;
      margin-right: 8px;
      position: relative;
      top: -2px;
    }
    
    svg {
      position: relative;
      top: -2px;
    }
  }
}
</style>
<script>
import {Fragment} from 'vue-fragment'

export default {
  //props: ,
  components: {
    'Fragment': Fragment
  },
  data() {
    return {}
  },
  computed: {
    fakeListItems() {
      let fakeResult;
  
      if(document.body.getAttribute('data-region') === 'spb'){
        fakeResult = [
          {
            title: "Цветной город",
            addr: "Санкт-Петербург, Красногвардейский",
            metro: "Академическая",
            goType: "car",
            time: "20 мин",
            price: "от 3.8 до 11.1 млн руб",
            counts: "3676 квартир в продаже",
          },
          {
            title: "Чистое небо",
            addr: "Санкт-Петербург, Приморский",
            metro: "Комендантский проспект",
            goType: "car",
            time: "15 мин",
            price: "от 4.9 до 16.4 млн руб",
            counts: "294 квартиры в продаже",
          },
          {
            title: "Солнечный город",
            addr: "Санкт-Петербург, Красносельский",
            metro: "Ленинский проспект",
            goType: "car",
            time: "25 мин",
            price: "от 4.1 до 13 млн руб",
            counts: "199 квартир в продаже",
          },
          {
            title: "Полис Приморский",
            addr: "Санкт-Петербург, Приморский",
            metro: "Комендантский проспект",
            goType: "car",
            time: "15 мин",
            price: "от 4.8 до 13 млн руб",
            counts: "185 квартир в продаже",
          },
          {
            title: "Солнечный город",
            addr: "Санкт-Петербург, Красносельский",
            metro: "Проспект Ветеранов",
            goType: "car",
            time: "20 мин",
            price: "от 4.5 до 16.3 млн руб",
            counts: "434 квартиры в продаже",
          },
          {
            title: "Зеленый квартал на Пулковских высотах",
            addr: "Санкт-Петербург, Московский",
            metro: "Московская",
            goType: "car",
            time: "20 мин",
            price: "от 4.7 до 14.8 млн руб",
            counts: "226 квартир в продаже",
          },
          {
            title: "Живи! В Рыбацком",
            addr: "Санкт-Петербург, Невский",
            metro: "Рыбацкое",
            goType: "people",
            time: "20 мин",
            price: "от 4.2 до 10.6 млн руб",
            counts: "582 квартиры в продаже",
          },
          {
            title: "Цивилизация",
            addr: "Санкт-Петербург, Невский",
            metro: "Улица Дыбенко",
            goType: "people",
            time: "20 мин",
            price: "от 8.1 до 15.5 млн руб",
            counts: "550 квартир в продаже",
          },
          {
            title: "Северная Долина",
            addr: "Санкт-Петербург, Выборгский",
            metro: "Парнас",
            goType: "people",
            time: "20 мин",
            price: "от 4.3 до 15.9 млн руб",
            counts: "689 квартир в продаже",
          },
        ]
      }else {
        fakeResult = [
          {
            title: "Митинский лес",
            addr: "Москва, СЗАО",
            metro: "Пятницкое шоссе",
            goType: "people",
            time: "20 мин",
            price: "от 5.4 до 16 млн руб",
            counts: "315 квартир в продаже",
          },
          {
            title: "Зеленая вертикаль",
            addr: "Москва, ЮАО",
            metro: "Лесопарковая",
            goType: "people",
            time: "3 мин",
            price: "от 9.9 до 21.6 млн руб",
            counts: "159 квартир в продаже",
          },
          {
            title: "PROFIT",
            addr: "Москва, ЮВАО",
            metro: "Нижегородская",
            goType: "people",
            time: "5 мин",
            price: "от 8 до 21.3 млн руб",
            counts: "326 квартир в продаже",
          },
          {
            title: "Лучи",
            addr: "Москва, ЗАО",
            metro: "Солнцево",
            goType: "people",
            time: "3 мин",
            price: "от 6.6 до 25.8 млн руб",
            counts: "208 квартир в продаже",
          },
          {
            title: "Люблинский парк",
            addr: "Москва, ЮВАО",
            metro: "Братиславская",
            goType: "people",
            time: "15 мин",
            price: "от 6.2 до 20 млн руб",
            counts: "406 квартир в продаже",
          },
          {
            title: "Level Южнопортовая",
            addr: "Москва, ЮВАО",
            metro: "Кожуховская",
            goType: "people",
            time: "10 мин",
            price: "от 8.1 до 25.1 млн руб",
            counts: "371 квартира в продаже",
          },
          {
            title: "Октябрьское поле",
            addr: "Москва, СЗАО",
            metro: "Октябрьское поле",
            goType: "people",
            time: "18 мин",
            price: "от 7.8 до 30 млн руб",
            counts: "175 квартир в продаже",
          },
          {
            title: "Бусиновский парк",
            addr: "Москва, САО",
            metro: "Ховрино",
            goType: "people",
            time: "20 мин",
            price: "от 6 до 20.1 млн руб",
            counts: "298 квартир в продаже",
          },
          {
            title: "City Bay",
            addr: "Москва, СЗАО",
            metro: "Тушинская",
            goType: "people",
            time: "20 мин",
            price: "от 9 до 36.8 млн руб",
            counts: "689 квартир в продаже",
          },
        ]
      }
      
      
      
      return fakeResult;
    }
  }
}
</script>
