const isPrivateMode = () => {
  return new Promise(function (resolve, reject) {
    try {
      const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') == -1 &&
        navigator.userAgent.indexOf('FxiOS') == -1;

      if (isSafari) {
        //Safari
        let e = false;

        if (window.safariIncognito) {
          e = true;
        } else {
          try {
            window.openDatabase(null, null, null, null);
            window.localStorage.setItem("test", 1);
            resolve(false);
          } catch (t) {
            e = true;
            resolve(true);
          }
          void !e && (e = !1, window.localStorage.removeItem("test"))
        }
      } else if (navigator.userAgent.includes("Firefox")) {
        //Firefox
        const db = indexedDB.open("test");
        db.onerror = () => resolve(true);
        db.onsuccess = () => resolve(false);
      } else if (navigator.userAgent.includes("Edge") || navigator.userAgent.includes("Trident") || navigator.userAgent.includes("msie")) {
        //Edge or IE
        if (!window.indexedDB && (window.PointerEvent || window.MSPointerEvent)) {
          resolve(true);
        } else {
          resolve(false);
        }
      } else {	//Normally ORP or Chrome
        //Other
        const fs = window.RequestFileSystem || window.webkitRequestFileSystem;

        if (!fs) {
          resolve(false)
        } else {
          fs(window.TEMPORARY, 100,
            () => resolve(false),
            () => resolve(true)
          );
        }
      }
    } catch (err) {
      resolve(false);
    }
  });
};

export default isPrivateMode;