<template>
  <Modal v-model="value" size="middle" @input="$emit('input', $event)">
    <ModalHeading>
      <slot name="heading" />
    </ModalHeading>
    <ModalContent>
      <slot name="content" />
    </ModalContent>
  </Modal>
</template>

<script>
  import Modal from '@/modules/quiz-landing/components/modal/Modal'
  import ModalHeading from '@/modules/quiz-landing/components/modal/ModalHeading'
  import ModalContent from '@/modules/quiz-landing/components/modal/ModalContent'

  export default {
    name: "SendFormModal",
    components: { Modal, ModalContent, ModalHeading },
    props: {
      value: Boolean,
    }
  }
</script>