<template>
    <div ref="modal" class="modal fade">
        <div class="modal-dialog" :class="[ currentSize ]">
            <div class="modal-content">
                <div class="popup_small adaptive_section">
                    <div class="section_lg">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'modal',
    props: {
      value: Boolean,
      size: {
        type: String,
        default: 'xsmall'
      },
      params: {
        type: Object,
        default: () => {}
      }
    },
    watch: {
      value(newValue) {
        newValue ? this.show() : this.hide();
        return newValue;
      }
    },
    data: () => ({
      modal: null,
      currentSize: 'modal-xs',
    }),
    created() {
      this.currentSize = sizes[this.size];
    },
    mounted() {
      if (this.$refs.hasOwnProperty('modal') && !this.modal) {
        this.modal = $(this.$refs.modal);
        this.modal.modal({...this.params, show: this.value});

        this.modal.off('show.bs.modal').on('show.bs.modal', () => {
          this.$emit('onShow', this.modal);
          this.$emit('input', true);
        });
        this.modal.off('hide.bs.modal').on('hide.bs.modal', () => {
          this.$emit('onHide', this.modal);
          this.$emit('input', false);
        });
        this.modal.off('shown.bs.modal').on('shown.bs.modal', () => this.$emit('onShown', this.modal));
        this.modal.off('hidden.bs.modal').on('hidden.bs.modal', () => this.$emit('onHidden', this.modal));
        this.modal.off('loaded.bs.modal').on('loaded.bs.modal', () => this.$emit('onLoaded', this.modal));
      }
    },
    methods: {
      hide() {
        this.modal.modal('hide');
      },

      show() {
        this.modal.modal('show');
      },
    }
  }

  const sizes = {
    xsmall: 'modal-xs',
    small: 'modal-sm',
    middle: 'modal-md',
    large: 'modal-lg'
  };
</script>