export default {
  inserted(el, binding) {
    $(el).tooltip(binding.value || {})
  },
  updated(el, binding) {
    $(el).tooltip(binding.value || {});
  },
  destroyed(el) {
    $(el).tooltip('destroy');
  }
}