// директива для маски ввода телефона
// можно использовать для input в любом месте
// <input v-phone />
export default {
    update(el) {
        el.onkeypress = el.oninput = function(e) {
            if (!e.isTrusted || this.value === '+') {
                return;
            }

            let cursorPosition = el.selectionStart;
            let isLastChar = cursorPosition === this.value.length;

            if (this.value === '7' || this.value === '+7' || this.value === '8') {
                this.value = '+7 (';
                return;
            }

            const x = this.value.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

            if (x[1] === '' || (e.inputType === "deleteContentBackward" && x[1] === '7' && x[2] === '')) {
                this.value = '';
                return;
            }

            if (x[1] !== '') {
                this.value = x[1] === '7' ? '+7 ' : (x[1] === '8' ? '+7 ' : '+7 ' + x[1]);
                if (x[2] !== '') {
                    this.value = this.value + '(' + x[2];
                }
            }
            if (x[1] === '7') {
                x[1] = '+7 '
            }
            if (x[3] !== '') {
                this.value = !x[3] ? x[1] + x[2] :
                    x[1] + '(' + x[2] + ') ' + x[3] + (x[4] ? '-' + x[4] : '') + (x[5] ? '-' + x[5] : '');
            }

            this.value = x[1] === '' ? '' : this.value;
            if (!isLastChar) {
                el.selectionStart = cursorPosition;
                el.selectionEnd = cursorPosition;
            }
        }
    },
}