<template>
    <form @submit="$event.preventDefault()">
        <div class="pb_10">
            <div v-for="field in formData.fields">
                <Checkbox
                    v-if="formData.type === 'checkbox'"
                    :name="formData.name"
                    :default-value="field.value"
                    :label="field.label"
                    v-model="value"
                ></Checkbox>
                <Radio
                    v-else-if="formData.type === 'radio'"
                    :name="formData.name"
                    :default-value="field.value"
                    :label="field.label"
                    v-model="value"
                ></Radio>
            </div>
        </div>
        <div class="d_f">
            <button
                v-if="!isFirst"
                @click="$store.commit('prev')"
                class="p_15 bgr_f5f5f5 name_color d_i_f mr_10 br_2 btn-svg-item-2"
            >
                <div class="">
                    <svg fill="#212121" class="w_24">
                        <use xlink:href="#arrowback-24"/>
                    </svg>
                </div>
                <div class="ml_15 fs_16 lh_24 fw_m hidden-xs pr_5">Назад</div>
            </button>
            <button
                :disabled="!isValid"
                @click="$store.commit('next')"
                class="p_15 bgr_f5f5f5 name_color btn-svg-item br_2 pl_20 d_f "
            >
                <div class="fs_16 lh_24 fw_m ">{{ isLast ? 'Показать результат' : 'Следующий вопрос' }}</div>
                <div class=" ml_15 ">
                    <svg fill="currentColor" class="w_24">
                        <use xlink:href="#arrowforward-24"/>
                    </svg>
                </div>
            </button>
        </div>
    </form>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import CheckValueMixin from "../mixins/CheckValueMixin";
  import Checkbox from './forms/Checkbox';
  import Radio from './forms/Radio';

  export default {
    name: 'dynamic-form',
    mixins: [CheckValueMixin],
    components: {
      Checkbox,
      Radio
    },
    computed: {
      ...mapGetters({
        formData: 'getForm',
        isLast: 'isLast',
        isFirst: 'isFirst'
      }),
      ...mapState({
        form: 'form',
        step: 'step'
      }),
      value: {
        get() {
          return this.form[this.formData.name];
        },
        set(value) {
          this.$store.commit('setValue', {
            name: this.formData.name,
            value,
          });
        }
      },
      isValid() {
        const thisValue = this.form[this.formData.name];

        if (this.formData.type === 'checkbox' && thisValue) {
          return thisValue.length > 0;
        } else {
          return thisValue;
        }
      }
    }
  }
</script>