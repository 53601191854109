import Vue from 'vue' //'vue/dist/vue.js'
import ru from 'vee-validate/dist/locale/ru'
import VeeValidate, { Validator } from 'vee-validate'
import * as uiv from 'uiv'

import './directives'


import Quiz from './modules/quiz/App.vue'
import QuizLanding from './modules/quiz-landing/App'
import QuizLandingGoogle from './modules/quiz-landing-google/App'

Vue.config.productionTip = false;

Vue.use(VeeValidate);
Vue.use(uiv, { prefix: 'bs' });

Validator.localize('ru', ru);

// Modules
Vue.component('quiz', Quiz);
Vue.component('quiz-landing', QuizLanding);
Vue.component('quiz-landing-google', QuizLandingGoogle);


Vue.mixin({
  methods: {
    ya(code) {
      const id = document.querySelector('[ya-counter]');

      if (id) {
        const name = 'yaCounter' + id.getAttribute('ya-counter');

        if (window.hasOwnProperty(name)) {
          window[name].reachGoal(code);
        }
      }
    }
  }
});

const handle = element => {
  if (element && !element.classList.contains('vue-completed')) {
    element.classList.add('vue-completed');

    new Vue({
      el: element
    });
  }
};

const app = () =>
[].forEach.call(document.querySelectorAll('.vue-init:not(.vue-completed)'), element => handle(element));

window.initVue = window.mortgageVue = window.chartPunctuialityCompany = app;
window.renderVue = handle;
window.renderVueAgain = element => {
  element.classList.remove('vue-completed');
  handle(element);
};

app();
