<template>
    <label class="form__label pointer" :class="{ active: check }">
        <input
                :name="name"
                type="checkbox"
                :value="defaultValue"
                :checked="check"
                @change="setValue"
                hidden="hidden"
        >
        <span class="form__checker"></span>
        <span class="text_smd">{{ label }}</span>
    </label>
</template>

<script>
  export default {
    name: 'custom-checkbox',
    props: ['value', 'label', 'defaultValue', 'name'],
    computed: {
      check() {
        return Array.isArray(this.value) && this.value.indexOf(String(this.defaultValue)) !== -1
      }
    },
    methods: {
      setValue(event) {
        let value = Array.isArray(this.value) ? [...this.value] : []
        let index = value.indexOf(String(this.defaultValue))

        if (index === -1) {
          value.push(String(event.target.value))
        } else {
          value.splice(index, 1)
        }

        this.$emit('input', value)
      }
    }
  }
</script>