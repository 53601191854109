<template>
    <div
        class="poll-border bl-theme-interactive br_2 c_p mb_10"
        :class="{ active: check }"
    >
        <label class="checkbox_item checker_inline">
            <div class="p_14">
                <input
                    :name="name"
                    type="radio"
                    :value="defaultValue"
                    :checked="check"
                    @change="setValue"
                >
                <span class="checker_item mt_14 ml_14 br_all"></span>
                <span class="checkbox_item_title">{{ label }}</span>
            </div>
        </label>
    </div>
</template>

<script>
  export default {
    name: 'custom-radio',
    props: ['value', 'label', 'defaultValue', 'name'],
    computed: {
      check() {
        return String(this.value) === String(this.defaultValue);
      }
    },
    methods: {
      setValue(event) {
        this.$emit('input', String(event.target.value));
      }
    }
  }
</script>