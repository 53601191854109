<template>
    <div v-if="label" class="poll-wrap-r p_40 bgr_fafafa height_all d_f">
        <div class="poll-second-col">
            <div class="poll-buble mb_30 bgr_white p_20 br_2 fs_14 lh_20 name_color">
                <div v-html="label.hint"></div>
                <a
                    v-if="online"
                    @click="showChat"
                    class="pt_10 d_b def_blue"
                    href="javascript:void(0)"
                >Начать онлайн-чат</a>
            </div>

            <div class="poll-consultant d_f a_i_c">
                <div class="mr_15 f_g_1 f_d_r">
                    <div class="fs_14 lh_20 ta_r fw_m">{{ label.name }}</div>
                    <div class="fs_14 lh_20 ta_r name_color">{{ label.position }}</div>
                </div>
                <div class="w_54 pos_rel c_p">
                    <a class="d_b" href="javascript:void(0)" @click="showChat">
                        <img
                            @click="showChat"
                            class="br_all"
                            style="height:54px;"
                            :src="label.image"
                            alt=""
                        />
                        <div class="poll-point-online br_all" :class="{ active: !chat || (chat && online) }"></div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'online-support',
    computed: mapState({
      label(state) {
        return state.quiz ? state.quiz.label : null;
      }
    }),
    data() {
      return {
        chat: false,
        online: false,
      }
    },
    mounted() {
      this.initChat()
        .then(() => {
          window.jivo_onLoadCallback = () => {
            window.jivo_cstm_widget = document.createElement('div');
            jivo_cstm_widget.setAttribute('id', 'jivo_custom_widget');
            document.body.appendChild(jivo_cstm_widget);

            this.online = jivo_config.chat_mode === 'online';

            emit.emit('quiz-chat-online', this.online);
          };

          window.jivo_onOpen = () => {
            if (jivo_cstm_widget) {
              jivo_cstm_widget.style.display = 'none';
            }
          };

          window.jivo_onClose = () => {
            if (jivo_cstm_widget) {
              jivo_cstm_widget.style.display = 'block';
            }
          };
        })
        .catch(() => this.chat = false);
    },
    methods: {
      initChat() {
        return new Promise((resolve, reject) => {
          const src = '//code.jivosite.com/script/widget/vApS2teNwC';
          const findScript = document.querySelector(`script[src*="code.jivosite.com"]`);

          if (!findScript) {
            const element = document.createElement('script');
            element.src = src;
            element.type = 'text/javascript';

            const last = document.getElementsByTagName('script')[0];
            last.parentNode.insertBefore(element, last);
          } else {
            findScript.setAttribute('src', src);
          }

          resolve();
        });
      },

      showChat() {
        if (this.online) {
          jivo_api.open();
        }
      }
    }
  }
</script>