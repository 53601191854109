<template>
    <label class="form__mediaWrapper" :class="{ active: check }">
        <input
            :name="name"
            type="checkbox"
            :value="defaultValue"
            :checked="check"
            @change="setValue"
            hidden="hidden"
        >
        <span class="form__mediaContainer pRel">
            <span class="form__mediaLayout layout layoutSize zIndex_3"></span>
            <span class="form__mediaImageBox pRel">
              <img class="form__mediaImage" :src="image" alt="">
              <span class="form__mediaChecker"></span>
              <span class="form__mediaImageLayout pAbs layoutSize zIndex_3"></span>
            </span>
            <span class="form__mediaText">{{ label }}</span>
          </span>
    </label>
</template>

<script>
  export default {
    name: 'custom-checkbox',
    props: ['value', 'label', 'defaultValue', 'name', 'image'],
    computed: {
      check() {
        return Array.isArray(this.value) && this.value.indexOf(String(this.defaultValue)) !== -1
      }
    },
    methods: {
      setValue(event) {
        let value = Array.isArray(this.value) ? [...this.value] : []
        let index = value.indexOf(String(this.defaultValue))

        if (index === -1) {
          value.push(String(event.target.value))
        } else {
          value.splice(index, 1)
        }

        this.$emit('input', value)
      }
    }
  }
</script>