<template>
    <form @submit="$event.preventDefault()">
        <div class="pb_10">
            <div
                class="poll-border bl-theme-interactive br_2 c_p mb_10"
                :class="{ active: value.indexOf('call') !== -1 }"
            >
                <label class="checkbox_item checker_inline">
                    <div class="p_12">
                        <input
                            type="checkbox"
                            v-model="value"
                            value="call"
                        >
                        <span class="checker_item mt_14 ml_14"></span>
                            <span class="checkbox_item_title d_f a_i_c">
                            <span class="lh_24 pr_5">Перезвонить мне</span>
                            <img src="/images/site/poll/callme.svg" alt="">
                        </span>
                    </div>

                </label>
            </div>
            <div
                class="poll-border bl-theme-interactive br_2 c_p mb_10"
                :class="{ active: value.indexOf('whatsapp') !== -1 }"
            >
                <label class="checkbox_item checker_inline">
                    <div class="p_12">
                        <input
                            type="checkbox"
                            v-model="value"
                            value="whatsapp"
                        >
                        <span class="checker_item mt_14 ml_14"></span>
                            <span class="checkbox_item_title d_f a_i_c">
                            <span class="lh_244 pr_5">Отправить в WhatsApp</span>
                            <img src="/images/site/poll/whatsapp.svg" alt="">
                        </span>
                    </div>

                </label>
            </div>
            <!--<div-->
                <!--class="poll-border bl-theme-interactive br_2 c_p mb_10"-->
                <!--:class="{ active: value.indexOf('telegram') !== -1 }"-->
            <!--&gt;-->
                <!--<label class="checkbox_item checker_inline">-->
                    <!--<div class="p_12">-->
                        <!--<input-->
                                <!--type="checkbox"-->
                                <!--v-model="value"-->
                                <!--value="telegram"-->
                        <!--&gt;-->
                        <!--<span class="checker_item mt_14 ml_14"></span>-->
                            <!--<span class="checkbox_item_title d_f a_i_c">-->
                            <!--<span class="lh_244 pr_5">Отправить в Telegram</span>-->
                            <!--<img src="/images/site/poll/telegram.svg" alt="">-->
                        <!--</span>-->
                    <!--</div>-->

                <!--</label>-->
            <!--</div>-->
        </div>
        <div class="d_f">
            <button
                v-if="!isFirst"
                @click="$store.commit('prev')"
                class="p_15 bgr_f5f5f5 name_color d_i_f mr_10 br_2 btn-svg-item-2"
            >
                <div class="">
                    <svg fill="#212121" class="w_24">
                        <use xlink:href="#arrowback-24"/>
                    </svg>
                </div>
                <div class="ml_15 fs_16 lh_24 fw_m hidden-xs pr_5">Назад</div>
            </button>

            <button
                :disabled="!isValid"
                @click="$store.commit('next')"
                class="p_15 bgr_f5f5f5 name_color btn-svg-item br_2 pl_20 d_f "
            >
                <div class="fs_16 lh_24 fw_m ">{{ isLast ? 'Показать результат' : 'Следующий вопрос' }}</div>
                <div class=" ml_15 ">
                    <svg fill="currentColor" class="w_24">
                        <use xlink:href="#arrowforward-24"/>
                    </svg>
                </div>
            </button>
        </div>
    </form>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';

  export default {
    name: 'contact-form',
    computed: {
      ...mapGetters({
        formData: 'getForm',
        isLast: 'isLast',
        isFirst: 'isFirst',
      }),
      ...mapState({
        form: 'form',
        step: 'step'
      }),
      value: {
        get() {
          return this.form[this.formData.name] || [];
        },
        set(value) {
          this.$store.commit('setValue', {
            name: this.formData.name,
            value
          });
        }
      },
      isValid() {
        const value = this.form[this.formData.name];
        return Array.isArray(value) && value.length > 0;
      }
    }
  }
</script>