export const mortgage = {
  namespaced: true,

  state: {
    minPayMonthLoader: false,
    prices: []
  },

  mutations: {
    setMinPayMonthLoader(state, loader) {
      state.minPayMonthLoader = loader
    },
    setPrices(state, prices) {
      state.prices = prices;
    }
  },

  actions: {
    async getMinPayMonth({ state }, params) {
      state.minPayMonthLoader = true;

      try {
        const { data } = await window.axios.post('/quiz/api/get-range-price-mortgage', params);
        state.prices = {...data};
        return data;
      } catch (e) {
        console.log(e.message);
      } finally {
        state.minPayMonthLoader = false;
      }
    },

    async getTotal({ rootState }) {
      rootState.loaderSend = true;

      try {
        const { data } = await window.axios.post(`/quiz/api/mortgage-total`, rootState.form);

        if (+data.flats === 0) {
          throw new Error(`
            В этом бюджете не найдено ни одной квартиры. 
            Попробуйте расширить бюджет, и мы обязательно что-нибудь подберем.
          `);
        }

        rootState.result = data;
      } catch (e) {
        rootState.error = e.message;
      } finally {
        rootState.loaderSend = false;
      }
    },
  },
};

export default mortgage;