<template>
    <div class="card__wrapper clrBlack">
        <div class="card__imageBox">
            <img :src="build.image" alt="" class="card__image">
        </div>
        <div class="card__title text_smd medium hover_blue">{{build.name}}</div>
        <div class="card__textBox">
            <div class="">{{build.address}}</div>

            <div v-if="build.metro.length && Number(build.metro[0]['on_transport']) <= 60" class="card__metroBox text_sm">
                <div :style="{'background-color': '#'+ build.metro[0]['color']}" class="card__metroDot"></div>
                <div class="card__metroName text_crop">{{metroName(build.metro[0])}}</div>

                <template v-if="Number(build.metro[0]['on_foot']) > 0 && Number(build.metro[0]['on_foot']) < 30" >
                    <div class="card__metroSvgBox">
                        <svg fill="#acacac" class="card__metroSvg w_12">
                            <use xlink:href="#walk-24"/>
                        </svg>
                    </div>
                    <div class="card__metroTime text_nowrap">
                        {{build.metro[0]['on_foot']}} мин.
                    </div>
                </template>
                <template v-else-if="Number(build.metro[0]['on_transport']) > 0" >
                    <div class="card__metroSvgBox">
                        <svg fill="#acacac" class="card__metroSvg w_12">
                            <use xlink:href="#bus-24"></use>
                        </svg>
                    </div>
                    <div class="card__metroTime text_nowrap">
                        {{build.metro[0]['on_transport']}} мин.
                    </div>
                </template>
            </div>
            <div v-else-if="build.railway && Number(build.railway.on_transport) <= 60" class="card__metroBox text_sm">
                <div class="card__metroName text_crop">{{build.railway.railway.name}} (ж/д)</div>

                <template v-if="Number(build.railway.on_foot) > 0 && Number(build.railway.on_foot) < 30" >
                    <div class="card__metroSvgBox">
                        <svg fill="#acacac" class="card__metroSvg w_12">
                            <use xlink:href="#walk-24"/>
                        </svg>
                    </div>
                    <div class="card__metroTime text_nowrap">
                        {{build.railway.on_foot}} мин.
                    </div>
                </template>
                <template v-else-if="Number(build.railway.on_transport) > 0" >
                    <div class="card__metroSvgBox">
                        <svg fill="#acacac" class="card__metroSvg w_12">
                            <use xlink:href="#bus-24"></use>
                        </svg>
                    </div>
                    <div class="card__metroTime text_nowrap">
                        {{build.railway.on_transport}} мин.
                    </div>
                </template>
            </div>
        </div>
        <div class="card__price bold text_md">{{price(build)}}</div>
        <div class="card__count text_md">{{showAdsCount(build.flat_count)}}</div>
    </div>

</template>

<script>
  import { TextHelper } from '../../../helpers/TextHelper';

  export default {
    props: ['build'],
    methods: {
      showAdsCount(data){
        if(data){
          return data + ' ' + TextHelper.declensionWords(data,['квартира','квартиры','квартир']) + ' в продаже'
        }
      },
      price(data) {
        if (data.min_price == data.max_price) {
          return Number(parseFloat(Math.round(data.min_price)).toFixed(1)).toLocaleString() + ' руб.';
        } else {
          return Number(parseFloat(Math.round(data.min_price)).toFixed(1)).toLocaleString() + ' - ' + Number(parseFloat(Math.round(data.max_price)).toFixed(1)).toLocaleString() + ' руб.';
        }
      },
      metroName(metro) {
        if (metro.build_year && metro.build_year >= new Date().getFullYear()) {
          return metro.name + ' (' + metro.build_year + ')'
        } else {
          return metro.name
        }
      }
    }
  }
</script>